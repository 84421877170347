import * as React from "react";
import MainLayout from "../components/layout/MainLayout";
import { FiPhoneCall } from "react-icons/fi";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Contact me | Husen Lokhandwala</title>
        <meta
          name="description"
          content="MERN Stack Developer | Husen Lokhandwala"
        />
        <meta property="og:image" content="/images/og_image.png" />
        <meta
          property="og:title"
          content="Hi 👋, My name is Husen Lokhandwala I build things for web, click here to contact me"
        />
        <meta
          name="keywords"
          content="husen lokhandwala, husain lokhandwala, hussain lokhandwala, contact husen, contact husain, contact husen lokhandwala, husen, husain, hussain, lokhandwala, lokhandvala, react dev, react developer, mern stack developer, developer, frontend developer, frontend, frontend dev, lead react developer, lead frontend developer, mozayc, vma techlabs"
        />
      </Helmet>
      <div className="min-h-[80vh] flex flex-wrap justify-center items-center text-3xl sm:text-4xl md:text-[58px]">
        <div className="text-center flex flex-col gap-y-5">
          <div className="">For any questions please mail us:</div>
          <div>
            <a
              href="mailto:coder.husen@gmail.com"
              className="bg-gradiant-color text-transparent bg-clip-text"
            >
              coder.husen@gmail.com
            </a>
            <div className="text-[18px] my-2">
              OR <FiPhoneCall className="inline" />
            </div>
            <a
              href="tel:+917016868559"
              className="bg-gradiant-color text-transparent bg-clip-text"
            >
              +917016868559
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Contact;
